<template>
  <div id="app" class="font-sans antialiased text-gray-900">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.getElementById('error-display').textContent += 'App.vue mounted\n';
  }
}
</script>