// src/services/api.js
import axios from 'axios';

// 創建一個 axios 實例，設置基本 URL 和請求頭
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  }
});

// 請求攔截器：在每個請求中添加 Telegram 初始化數據
api.interceptors.request.use(config => {
  const tg = window.Telegram.WebApp;
  config.headers['X-Telegram-Init-Data'] = tg.initData;
  return config;
});

export default api;

/**
 * 驗證當前用戶是否為管理員
 * @returns {Promise} 返回一個 Promise，解析為包含 isAdmin 屬性的對象
 */
export const verifyAdmin = () => api.get('/verify-admin');

/**
 * 獲取用戶列表
 * @param {number} page - 當前頁碼，默認為 1
 * @param {number} limit - 每頁顯示的用戶數量，默認為 10
 * @param {string} search - 搜索關鍵字，默認為空字符串
 * @returns {Promise} 返回一個 Promise，解析為包含用戶列表和分頁信息的對象
 */
export const getUsers = (page = 1, limit = 10, search = '') => 
  api.get(`/users?page=${page}&limit=${limit}&search=${search}`);

/**
 * 獲取特定用戶的購買記錄
 * @param {string} userId - 用戶 ID
 * @param {number} page - 當前頁碼，默認為 1
 * @param {number} limit - 每頁顯示的記錄數量，默認為 5
 * @returns {Promise} 返回一個 Promise，解析為包含用戶購買記錄和分頁信息的對象
 */
export const getUserPurchases = (userId, page = 1, limit = 5) => 
  api.get(`/users/${userId}/purchases?page=${page}&limit=${limit}`);

/**
 * 獲取所有用戶的購買歷史
 * @param {number} page - 當前頁碼，默認為 1
 * @param {number} limit - 每頁顯示的記錄數量，默認為 10
 * @returns {Promise} 返回一個 Promise，解析為包含所有購買記錄和分頁信息的對象
 */
export const getPurchaseHistory = (page = 1, limit = 10) => 
  api.get(`/purchase-history?page=${page}&limit=${limit}`);

/**
 * 獲取所有會員等級
 * @returns {Promise} 返回一個 Promise，解析為包含所有會員等級信息的數組
 */
export const getMembershipLevels = () => api.get('/membership-levels');

/**
 * 更新用戶的會員等級
 * @param {string} userId - 用戶 ID
 * @param {string} membershipId - 新的會員等級 ID
 * @returns {Promise} 返回一個 Promise，解析為更新操作的結果
 */
export const updateMembership = (userId, membershipId) => 
  api.post('/update-membership', { userId, membershipId });

/**
 * 獲取推廣統計數據
 * @returns {Promise} 返回一個 Promise，解析為包含總歷史返利和當前實際返利總額的對象
 */
export const getReferralStats = () => api.get('/referral-stats');

/**
 * 獲取排行榜數據
 * @returns {Promise} 返回一個 Promise，解析為包含邀請人數排行和返利總額排行的對象
 */
export const getTopReferrers = () => api.get('/top-referrers');

/**
 * 更新用戶的推廣信息
 * @param {string} userId - 用戶 ID
 * @param {Object} data - 包含要更新的推廣信息的對象，如獎勵比例和當前實際獎勵
 * @returns {Promise} 返回一個 Promise，解析為更新操作的結果
 */
export const updateUserReferral = (userId, data) => 
  api.post('/update-user-referral', { userId, ...data });

/**
 * 獲取推廣等級列表
 * @returns {Promise} 返回一個 Promise，解析為包含所有推廣等級信息的數組
 */
export const getReferralLevels = () => api.get('/referral-levels');
