<!-- src/components/PurchaseHistory.vue -->
<template>
  <div class="bg-white shadow rounded-lg p-6">
    <h2 class="text-xl font-semibold mb-4">购买历史</h2>
    <ul class="space-y-4">
      <li v-for="purchase in purchases" :key="purchase._id" class="bg-gray-50 p-4 rounded">
        <div>
          <strong>{{ purchase.firstName }} ({{ purchase.nickname }})</strong>
          <p class="text-sm text-gray-600">产品: {{ purchase.productName }}</p>
          <p class="text-sm text-gray-600">金额: ${{ purchase.amount }}</p>
          <p class="text-sm text-gray-600">日期: {{ formatDate(purchase.purchaseDate) }}</p>
        </div>
      </li>
    </ul>

    <!-- 分页控件 -->
    <div class="mt-4 flex justify-between items-center">
      <button @click="loadPreviousPage" :disabled="currentPage === 1" class="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded">上一页</button>
      <span>第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
      <button @click="loadNextPage" :disabled="currentPage === totalPages" class="bg-gray-300 hover:bg-gray-400 px-4 py-2 rounded">下一页</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getPurchaseHistory } from '../services/api';

export default {
  name: 'PurchaseHistory',
  setup() {
    const purchases = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const itemsPerPage = 10;

    const loadPurchaseHistory = async (page = 1) => {
      try {
        const response = await getPurchaseHistory(page, itemsPerPage);
        purchases.value = response.data.purchases;
        totalPages.value = response.data.pages;
        currentPage.value = page;
      } catch (error) {
        console.error('Failed to load purchase history:', error);
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    const loadPreviousPage = () => {
      if (currentPage.value > 1) {
        loadPurchaseHistory(currentPage.value - 1);
      }
    };

    const loadNextPage = () => {
      if (currentPage.value < totalPages.value) {
        loadPurchaseHistory(currentPage.value + 1);
      }
    };

    onMounted(() => {
      loadPurchaseHistory();
    });

    return {
      purchases,
      currentPage,
      totalPages,
      formatDate,
      loadPreviousPage,
      loadNextPage
    };
  }
}
</script>