<template>
  <div v-if="isLoading" class="flex items-center justify-center min-h-screen bg-gray-100">
    <p class="text-xl text-gray-600">正在加载...</p>
  </div>
  <div v-else-if="isAuthorized" class="p-6 bg-gray-100 min-h-screen">
    <h1 class="text-3xl font-bold text-gray-800 mb-6">
      管理仪表板 
      <span v-if="adminName" class="text-xl font-normal text-gray-600 ml-2">
        - 欢迎尊荣的管理员{{ adminName }}
      </span>
    </h1>
    <!-- 其余的仪表板内容 -->
    <div class="mb-6 border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <button
          v-for="tab in tabs"
          :key="tab.name"
          @click="currentTab = tab.name"
          :class="[
            currentTab === tab.name
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
          ]"
        >
          {{ tab.label }}
        </button>
      </nav>
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <UserManagement v-if="currentTab === 'users'" />
      <PurchaseHistory v-if="currentTab === 'purchases'" />
           <PromotionManagement v-if="currentTab === 'promotion'" />
    </div>
  </div>
  <div v-else class="flex items-center justify-center min-h-screen bg-gray-100">
    <p class="text-xl text-red-600">访问被拒绝。您没有权限访问此页面。</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import UserManagement from './UserManagement.vue';
import PurchaseHistory from './PurchaseHistory.vue';
import PromotionManagement from './PromotionManagement.vue';
import { verifyAdmin } from '@/services/api';

const currentTab = ref('users');
const adminName = ref('');
const isAuthorized = ref(false);
const isLoading = ref(true);

const tabs = [
  { name: 'users', label: '用户管理' },
  { name: 'purchases', label: '购买记录' },
  { name: 'promotion', label: '推广管理' }
];

onMounted(async () => {
  try {
    const tg = window.Telegram?.WebApp;
    if (!tg) {
      throw new Error('不在 Telegram 环境中');
    }

    // 验证管理员身份
    const response = await verifyAdmin();
    isAuthorized.value = response.data.isAdmin;

    if (isAuthorized.value) {
      adminName.value = tg.initDataUnsafe?.user?.first_name || '管理员';
    }
  } catch (error) {
    console.error('Authorization failed:', error);
    isAuthorized.value = false;
  } finally {
    isLoading.value = false;
  }
});
</script>