<template>
  <div class="promotion-management p-4 sm:p-6 bg-gray-100">
    <h1 class="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-center text-gray-800">推广管理</h1>

    <!-- 总体统计 -->
    <div class="mb-6 sm:mb-8 bg-white p-4 sm:p-6 rounded-lg shadow-md">
      <h2 class="text-xl sm:text-2xl font-semibold mb-4 text-gray-700">推广总数据</h2>
      <div v-if="statsLoading" class="text-center text-gray-500">加载中...</div>
      <div v-else-if="statsError" class="text-center text-red-500">{{ statsError }}</div>
      <div v-else class="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div class="bg-blue-100 p-4 rounded-lg">
          <p class="text-base sm:text-lg font-medium text-blue-800">总历史返利：</p>
          <p class="text-xl sm:text-2xl font-bold text-blue-900">{{ formatCurrency(stats.totalHistoricalRewards) }}</p>
        </div>
        <div class="bg-green-100 p-4 rounded-lg">
          <p class="text-base sm:text-lg font-medium text-green-800">当前实际返利总余额：</p>
          <p class="text-xl sm:text-2xl font-bold text-green-900">{{ formatCurrency(stats.currentTotalRewards) }}</p>
        </div>
      </div>
    </div>

    <!-- 排行榜 -->
    <div class="mb-6 sm:mb-8 bg-white p-4 sm:p-6 rounded-lg shadow-md">
      <h2 class="text-xl sm:text-2xl font-semibold mb-4 text-gray-700">排行榜</h2>
      <div v-if="rankingsLoading" class="text-center text-gray-500">加载中...</div>
      <div v-else-if="rankingsError" class="text-center text-red-500">{{ rankingsError }}</div>
      <div v-else class="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <h3 class="text-lg sm:text-xl font-medium mb-2 text-gray-600">推广人数排行</h3>
          <ul class="space-y-2">
            <li v-for="(user, index) in rankings.byCount" :key="user.userId" class="flex justify-between items-center bg-gray-50 p-2 rounded text-sm sm:text-base">
              <span>{{ index + 1 }}. {{ user.firstName }} ({{ user.nickname }})</span>
              <span class="font-semibold">{{ user.referralCount }} 人</span>
            </li>
          </ul>
        </div>
        <div>
          <h3 class="text-lg sm:text-xl font-medium mb-2 text-gray-600">返利总额排行</h3>
          <ul class="space-y-2">
            <li v-for="(user, index) in rankings.byRewards" :key="user.userId" class="flex justify-between items-center bg-gray-50 p-2 rounded text-sm sm:text-base">
              <span>{{ index + 1 }}. {{ user.firstName }} ({{ user.nickname }})</span>
              <span class="font-semibold">{{ formatCurrency(user.totalHistoricalRewards) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 用户列表 -->
    <div class="bg-white p-4 sm:p-6 rounded-lg shadow-md">
      <h2 class="text-xl sm:text-2xl font-semibold mb-4 text-gray-700">用户列表</h2>
      
      <!-- 搜索栏 -->
      <div class="mb-4">
        <input 
          v-model="searchTerm"
          @input="debouncedSearch"
          type="text"
          placeholder="搜索用户..."
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
      </div>

      <div v-if="usersLoading" class="text-center text-gray-500">加载用户数据中...</div>
      <div v-else-if="usersError" class="text-center text-red-500">{{ usersError }}</div>
      <div v-else>
        <div v-for="user in users" :key="user.userId" class="mb-4 p-4 border rounded-lg hover:bg-gray-50">
          <div class="flex justify-between items-center mb-2">
            <h3 class="text-base sm:text-lg font-semibold">{{ user.firstName }} ({{ user.nickname }}) {{ user.userId }}</h3>
            <button @click="editUser(user)" class="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm">
              编辑
            </button>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 text-sm sm:text-base">
            <div>推广等级: {{ user.referralLevel }}</div>
            <div>返利比例: {{ getRewardPercentage(user.referralLevel) }}%</div>
            <div>特殊返利比例: {{ formatPercentage(user.specialRewardPercentage) }}%</div>
            <div>历史返利: {{ formatCurrency(user.totalHistoricalRewards) }}</div>
            <div>当前余额: {{ formatCurrency(user.currentAvailableRewards) }}</div>
            <div>邀请人数: {{ user.referralCount }}</div>
          </div>
        </div>
      </div>

      <!-- 分页控制 -->
      <div v-if="totalPages > 1" class="flex justify-between items-center mt-6">
        <button @click="changePage(-1)" :disabled="currentPage === 1" class="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 text-sm sm:text-base">上一页</button>
        <span class="text-sm sm:text-base">第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
        <button @click="changePage(1)" :disabled="currentPage === totalPages" class="px-4 py-2 bg-blue-500 text-white rounded disabled:bg-gray-300 text-sm sm:text-base">下一页</button>
      </div>
    </div>

    <!-- 编辑用户模态框 -->
    <div v-if="showEditModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div class="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 class="text-xl sm:text-2xl font-semibold mb-4">编辑用户</h2>
        <div v-if="editingUser">
          <div class="mb-4">
            <label class="block mb-1">特殊返利比例 (%) <span class="text-sm text-gray-500">(原值: {{(editingUser.originalSpecialRewardPercentage) }}%)</span></label>
            <input v-model.number="editingUser.specialRewardPercentage" type="number" step="1" min="0" max="100" class="w-full px-3 py-2 border rounded">
          </div>
          <div class="mb-4">
            <label class="block mb-1">当前可用余额 <span class="text-sm text-gray-500">(原值: {{ formatCurrency(editingUser.originalCurrentAvailableRewards) }})</span></label>
            <input v-model.number="editingUser.currentAvailableRewards" type="number" step="0.01" class="w-full px-3 py-2 border rounded">
          </div>
          <div class="flex justify-end">
            <button @click="closeEditModal" class="px-4 py-2 bg-gray-300 rounded mr-2 text-sm sm:text-base">取消</button>
            <button @click="saveUserEdit" class="px-4 py-2 bg-blue-500 text-white rounded text-sm sm:text-base">保存</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 反馈信息 -->
    <div v-if="feedback.show" :class="['fixed bottom-4 right-4 p-4 rounded-lg shadow-lg', feedback.type === 'success' ? 'bg-green-500' : 'bg-red-500']">
      <p class="text-white">{{ feedback.message }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getUsers, getReferralStats, getTopReferrers, updateUserReferral,getReferralLevels  } from '@/services/api';

export default {
  name: 'PromotionManagement',
  setup() {
    const users = ref([]);
    const stats = ref({ totalHistoricalRewards: 0, currentTotalRewards: 0 });
    const rankings = ref({ byCount: [], byRewards: [] });
    const usersLoading = ref(true);
    const statsLoading = ref(true);
    const rankingsLoading = ref(true);
    const usersError = ref(null);
    const statsError = ref(null);
    const rankingsError = ref(null);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const searchTerm = ref('');
    const showEditModal = ref(false);
    const editingUser = ref(null);
    const feedback = ref({ show: false, message: '', type: 'success' });

    const formatPercentage = (value) => {
      if (value == null) return 0;
      return Math.floor(value * 100);
    };


    const formatCurrency = (value) => {
      return value != null ? parseFloat(value).toFixed(2) : '0.00';
    };

    const fetchUsers = async (page = 1, search = '') => {
      usersLoading.value = true;
      usersError.value = null;
      try {
        const response = await getUsers(page, 10, search);
        users.value = response.data.users;
        currentPage.value = response.data.page;
        totalPages.value = response.data.pages;
      } catch (err) {
        usersError.value = '获取用户数据失败: ' + err.message;
      } finally {
        usersLoading.value = false;
      }
    };

    const fetchStats = async () => {
      statsLoading.value = true;
      statsError.value = null;
      try {
        const response = await getReferralStats();
        stats.value = response.data;
      } catch (err) {
        statsError.value = '获取统计数据失败: ' + err.message;
      } finally {
        statsLoading.value = false;
      }
    };

    const fetchRankings = async () => {
      rankingsLoading.value = true;
      rankingsError.value = null;
      try {
        const response = await getTopReferrers();
        rankings.value = response.data;
      } catch (err) {
        rankingsError.value = '获取排行榜数据失败: ' + err.message;
      } finally {
        rankingsLoading.value = false;
      }
    };

    const changePage = (delta) => {
      const newPage = currentPage.value + delta;
      if (newPage >= 1 && newPage <= totalPages.value) {
        fetchUsers(newPage, searchTerm.value);
      }
    };

    const debouncedSearch = (() => {
      let timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fetchUsers(1, searchTerm.value);
        }, 300);
      };
    })();
    
     const referralLevels = ref([]);

    const fetchReferralLevels = async () => {
      try {
        const response = await getReferralLevels();
        referralLevels.value = response.data;
      } catch (err) {
        showFeedback('获取推广等级失败: ' + err.message, 'error');
      }
    };

    const getRewardPercentage = (level) => {
      const levelInfo = referralLevels.value.find(l => l.level === level);
      return levelInfo ? formatPercentage(levelInfo.rewardPercentage) : '0.00';
    };

   const editUser = (user) => {
  editingUser.value = { 
    ...user,
    specialRewardPercentage: formatPercentage(user.specialRewardPercentage),
    currentAvailableRewards: parseFloat(user.currentAvailableRewards),
    originalSpecialRewardPercentage: formatPercentage(user.specialRewardPercentage),
    originalCurrentAvailableRewards: user.currentAvailableRewards
  };
  showEditModal.value = true;
};

    const closeEditModal = () => {
      showEditModal.value = false;
      editingUser.value = null;
    };

    const showFeedback = (message, type = 'success') => {
      feedback.value = { show: true, message, type };
      setTimeout(() => {
        feedback.value.show = false;
      }, 3000);
    };

 const saveUserEdit = async () => {
      try {
        const response = await updateUserReferral(editingUser.value.userId, {
          specialRewardPercentage: editingUser.value.specialRewardPercentage / 100, // 轉換回小數
          currentAvailableRewards: editingUser.value.currentAvailableRewards
        });

        if (response.data && response.data.user) {
          const updatedUser = response.data.user;
          const updatedUserIndex = users.value.findIndex(u => u.userId === updatedUser.userId);
          if (updatedUserIndex !== -1) {
            users.value[updatedUserIndex] = {
              ...users.value[updatedUserIndex],
              specialRewardPercentage: updatedUser.specialRewardPercentage,
              currentAvailableRewards: updatedUser.currentAvailableRewards
            };
          }
        }

        closeEditModal();
        showFeedback('用戶數據更新成功', 'success');
        await fetchUsers(currentPage.value, searchTerm.value);
      } catch (err) {
        showFeedback('更新用戶數據失敗: ' + err.message, 'error');
      }
    };
    onMounted(() => {
      fetchUsers();
      fetchStats();
      fetchRankings();
      fetchReferralLevels();
    });

   return {
      users,
      stats,
      rankings,
      usersLoading,
      statsLoading,
      rankingsLoading,
      usersError,
      statsError,
      rankingsError,
      currentPage,
      totalPages,
      searchTerm,
      showEditModal,
      editingUser,
      feedback,
      formatPercentage,
      formatCurrency,
      changePage,
      debouncedSearch,
      editUser,
      closeEditModal,
      referralLevels,
      getRewardPercentage,
      saveUserEdit
    };
  }
};
</script>