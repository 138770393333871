import { createRouter, createWebHistory } from 'vue-router'
import AdminDashboard from '@/components/AdminDashboard.vue'

const routes = [
  {
    path: '/',
    name: 'AdminDashboard',
    component: AdminDashboard
  }
]

const router = createRouter({
  history: createWebHistory('/admindashboard/'),
  routes
})

export default router