import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css' // 这里导入 Tailwind CSS

const app = createApp(App)

// 将 Telegram WebApp 初始化数据添加到全局属性中
app.config.globalProperties.$telegramInitData = window.Telegram?.WebApp?.initData || '';

app.use(router)

app.mount('#app')