<!-- src/components/UserManagement.vue -->
<template>
  <div class="bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="p-6">
      <h2 class="text-2xl font-semibold text-gray-800 mb-4">用户管理</h2>
      
      <!-- 搜索栏 -->
      <div class="mb-4">
        <input 
          v-model="searchTerm"
          @input="searchUsers"
          type="text"
          placeholder="搜索用户..."
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
      </div>

      <!-- 用户列表 -->
      <div class="overflow-x-auto">
        <div class="grid grid-cols-1 gap-4">
          <div v-for="user in users" :key="user.userId" class="bg-gray-50 p-4 rounded-lg shadow">
            <div class="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2">
              <div class="text-lg font-medium text-gray-900 mb-2 sm:mb-0">
                {{ user.firstName }} ({{ user.nickname }})
              </div>
              <span class="px-2 py-1 text-xs font-semibold rounded-full bg-green-100 text-green-800">
                {{ user.membership ? user.membership.name : '无会员等级' }}
              </span>
            </div>
            <div class="flex flex-col sm:flex-row gap-2 mt-2">
              <button @click="showUpdateMembershipModal(user.userId)" class="text-white bg-blue-500 hover:bg-blue-600 px-3 py-1 rounded text-sm">
                更新会员等级
              </button>
              <button v-if="user.hasPurchases" @click="viewPurchaseHistory(user.userId)" class="text-white bg-green-500 hover:bg-green-600 px-3 py-1 rounded text-sm">
                查看购买记录
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 分页控件 -->
      <div class="mt-4 flex flex-col sm:flex-row justify-between items-center">
        <div class="flex gap-2 mb-2 sm:mb-0">
          <button @click="loadPreviousPage" :disabled="currentPage === 1" class="px-4 py-2 border rounded text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50">
            上一页
          </button>
          <button @click="loadNextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border rounded text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50">
            下一页
          </button>
        </div>
        <span class="text-sm text-gray-700">第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
      </div>
    </div>

    <!-- 更新会员等级的模态框 -->
    <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">更新会员等级</h3>
          <div class="mt-2 px-7 py-3">
            <select v-model="selectedMembership" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option v-for="level in membershipLevels" :key="level._id" :value="level._id">
                {{ level.name }}
              </option>
            </select>
          </div>
          <div class="items-center px-4 py-3">
            <button @click="updateMembership" class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300">
              更新
            </button>
          </div>
          <div class="items-center px-4 py-3">
            <button @click="showModal = false" class="px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300">
              取消
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 购买记录模态框 -->
    <div v-if="showPurchaseModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div class="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">购买记录</h3>
        <div class="overflow-x-auto">
          <table class="min-w-full bg-white">
            <thead class="bg-gray-100">
              <tr>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">产品</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">金额</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">日期</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="purchase in userPurchases" :key="purchase._id">
                <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{{ purchase.productName }}</td>
                <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-900">${{ purchase.amount }}</td>
                <td class="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{{ formatDate(purchase.purchaseDate) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4 flex justify-end">
          <button @click="showPurchaseModal = false" class="px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300">
            关闭
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getUsers, getMembershipLevels, updateMembership as updateMembershipApi, getUserPurchases } from '../services/api';

export default {
  name: 'UserManagement',
  setup() {
    const users = ref([]);
    const membershipLevels = ref([]);
    const showModal = ref(false);
    const showPurchaseModal = ref(false);
    const selectedUserId = ref(null);
    const selectedMembership = ref(null);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const itemsPerPage = 10;
    const searchTerm = ref('');
    const userPurchases = ref([]);

    const loadUsers = async (page = 1, search = '') => {
      try {
        const response = await getUsers(page, itemsPerPage, search);
        users.value = response.data.users;
        totalPages.value = response.data.pages;
        currentPage.value = page;
      } catch (error) {
        console.error('Failed to load users:', error);
      }
    };

    const loadMembershipLevels = async () => {
      try {
        const response = await getMembershipLevels();
        membershipLevels.value = response.data;
      } catch (error) {
        console.error('Failed to load membership levels:', error);
      }
    };

    const showUpdateMembershipModal = (userId) => {
      selectedUserId.value = userId;
      showModal.value = true;
    };

    const updateMembership = async () => {
      try {
        await updateMembershipApi(selectedUserId.value, selectedMembership.value);
        await loadUsers(currentPage.value, searchTerm.value);
        showModal.value = false;
      } catch (error) {
        console.error('Failed to update membership:', error);
      }
    };

    const loadPreviousPage = () => {
      if (currentPage.value > 1) {
        loadUsers(currentPage.value - 1, searchTerm.value);
      }
    };

    const loadNextPage = () => {
      if (currentPage.value < totalPages.value) {
        loadUsers(currentPage.value + 1, searchTerm.value);
      }
    };

    const searchUsers = () => {
      loadUsers(1, searchTerm.value);
    };

    const viewPurchaseHistory = async (userId) => {
      try {
        const response = await getUserPurchases(userId);
        userPurchases.value = response.data.purchases;
        showPurchaseModal.value = true;
      } catch (error) {
        console.error('Failed to load purchase history:', error);
      }
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString();
    };

    onMounted(() => {
      loadUsers();
      loadMembershipLevels();
    });

    return {
      users,
      membershipLevels,
      showModal,
      showPurchaseModal,
      selectedMembership,
      currentPage,
      totalPages,
      searchTerm,
      userPurchases,
      showUpdateMembershipModal,
      updateMembership,
      loadPreviousPage,
      loadNextPage,
      searchUsers,
      viewPurchaseHistory,
      formatDate
    };
  }
}
</script>